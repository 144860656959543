"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'EQXBV',
  bodyClass: 'risk-device-page',
  components: {},
  data: function data() {
    return {
      uriPortal: process.env.VUE_APP_BASE_URL_EQXBV
    };
  },
  methods: {
    goBack: function goBack() {
      this.$router.back();
    }
  }
};