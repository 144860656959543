"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card card-api-request-component"
  }, [_c("div", {
    staticClass: "card-header"
  }, [_c("div", {
    staticClass: "col-detail-header"
  }, [_c("h3", {
    staticClass: "title"
  }, [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")])])]), _vm._v(" "), _c("div", {
    staticClass: "card-content scroll"
  }, [!_vm.rows.length && !_vm.isLoading ? _c("div", {
    staticClass: "content-text-message"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon-checked-grenn.png")
    }
  }), _vm._v(" "), _c("h1", [_vm._v("\n        Sem requisições até o momento\n      ")])]) : _c("b-table", {
    ref: "multiSortTable",
    attrs: {
      data: _vm.rows,
      paginated: "",
      "backend-pagination": "",
      total: _vm.total,
      "per-page": _vm.perPage,
      "sort-icon": _vm.sortIcon,
      "sort-icon-size": _vm.sortIconSize,
      loading: _vm.isLoading,
      "aria-next-label": "Next page",
      "aria-previous-label": "Previous page",
      "aria-page-label": "Page",
      "aria-current-label": "Current page"
    },
    on: {
      "page-change": _vm.onPageChange
    }
  }, _vm._l(_vm.columns, function (column, index) {
    return _c("b-table-column", {
      key: index,
      attrs: {
        field: column.field,
        label: column.label
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(props) {
          return [_vm._v("\n          " + _vm._s(props.row[column.field]) + "\n        ")];
        }
      }], null, true)
    });
  }), 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;