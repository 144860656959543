"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-dashboard-details-service"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v("\n          Serviços ativos\n        ")])])])]), _vm._v(" "), _c("tiles", [_c("div", {
    staticClass: "card-filters columns"
  }, [_c("div", {
    staticClass: "content-filters is-flex-direction-column mr-5 column"
  }, [_c("h3", [_vm._v("Período:")]), _vm._v(" "), _c("div", {
    staticClass: "is-flex"
  }, [_c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("b-datepicker", {
    attrs: {
      locale: "pt-BR",
      placeholder: "Selecione o périodo",
      "mobile-native": false,
      range: ""
    },
    model: {
      value: _vm.dates,
      callback: function callback($$v) {
        _vm.dates = $$v;
      },
      expression: "dates"
    }
  })], 1), _vm._v(" "), _c("b-button", {
    attrs: {
      "icon-right": "magnify"
    },
    on: {
      click: _vm.updateDataItems
    }
  }, [_vm._v("\n            Filtrar\n          ")])], 1)])])]), _vm._v(" "), _c("tiles", {
    attrs: {
      "max-per-row": 4
    }
  }, [_c("b-tooltip", {
    staticClass: "tooltip-service",
    attrs: {
      position: "is-bottom",
      "is-multiline": ""
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c("p", {
          domProps: {
            innerHTML: _vm._s(_vm.servicesName)
          }
        })];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-primary",
      number: _vm.totalServicesNow || 0,
      label: "Serviços chamados",
      load: _vm.loadCards
    }
  })], 1), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-success",
      number: _vm.details.totalRequestsCreateds || 0,
      label: "Total de requisições",
      load: _vm.loadCards
    }
  }), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-info",
      number: _vm.details.totalRequestsErrors || 0,
      "error-percent": _vm.details.percentErrors || 0,
      label: "Total de erros",
      load: _vm.loadCards
    }
  }), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-info",
      number: _vm.details.totalRequestsSuccess || 0,
      "error-percent": _vm.details.PercentNotFetchs || 0,
      label: "Total de sucessos",
      load: _vm.loadCards
    }
  })], 1), _vm._v(" "), _c("card-component", {
    attrs: {
      title: _vm.titleMonitoramento,
      icon: "finance",
      "header-icon": "reload"
    },
    on: {
      "header-icon-click": _vm.fillChartData
    }
  }, [!_vm.loadChart ? _c("div", {
    staticClass: "chart-area"
  }, [_c("line-chart", {
    style: {
      height: "100%"
    },
    attrs: {
      "chart-data": _vm.chartData,
      "chart-options": _vm.chartOptions
    }
  })], 1) : _c("div", {
    staticClass: "chart-area"
  }, [_c("b-skeleton", {
    attrs: {
      height: "180px"
    }
  })], 1)]), _vm._v(" "), _c("card-component", {
    staticClass: "has-table has-mobile-sort-spaced",
    attrs: {
      title: "Última requisição por serviço",
      "header-icon": "reload"
    },
    on: {
      "header-icon-click": _vm.getDetailsList
    }
  }, [!_vm.loadLastRequests ? _c("table-services-actives-calls-list", {
    attrs: {
      list: _vm.listLastRequests
    }
  }) : _c("div", [_c("b-skeleton", {
    attrs: {
      height: "120px"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("card-component", {
    staticClass: "has-table has-mobile-sort-spaced",
    attrs: {
      title: "Quantidade de requisições monetizaveis",
      "header-icon": "reload"
    },
    on: {
      "header-icon-click": _vm.getRequestsTotalsMonetizables
    }
  }, [_vm.loadRequestsTotalsMonetizables ? _c("b-skeleton", {
    attrs: {
      height: "450px"
    }
  }) : _vm._e(), _vm._v(" "), !_vm.loadRequestsTotalsMonetizables && _vm.chartRequestsMonetizablesTotal ? _c("doughnut-chart", {
    style: {
      height: "100%"
    },
    attrs: {
      "chart-data": _vm.chartRequestsMonetizablesTotal
    }
  }) : _vm._e()], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("card-component", {
    staticClass: "has-table has-mobile-sort-spaced",
    attrs: {
      title: "Quantidade de requisições totais",
      "header-icon": "reload"
    },
    on: {
      "header-icon-click": _vm.getRequestsTotals
    }
  }, [_vm.loadRequestsTotals ? _c("b-skeleton", {
    attrs: {
      height: "450px"
    }
  }) : _vm._e(), _vm._v(" "), !_vm.loadRequestsTotals && _vm.chartRequestsTotal ? _c("doughnut-chart", {
    style: {
      height: "100%"
    },
    attrs: {
      "chart-data": _vm.chartRequestsTotal
    }
  }) : _vm._e()], 1)], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;