"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-dash"
  }, [_vm._m(0), _vm._v(" "), _vm.list.length ? _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "columns cols-cards-info is-multiline"
  }, _vm._l(_vm.list, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "column is-one-third"
    }, [_c("card-monitor", {
      attrs: {
        title: "",
        service: item.name,
        logo: "",
        active: true
      },
      nativeOn: {
        click: function click($event) {
          return _vm.openCard(item);
        }
      }
    })], 1);
  }), 0)]) : _c("not-found", {
    attrs: {
      text: "Não foram encontrados serviços ativos."
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("h1", {
    staticClass: "title-page"
  }, [_vm._v("Controle de Acesso")])])]);
}];
render._withStripped = true;