"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sampleChartData = exports.resetColorIndex = exports.getSequentialColor = exports.getRandomColor = exports.datasetObjectItemPainel = exports.datasetObjectItem = exports.chartColors = exports.CHART_COLORS = void 0;
exports.transparentize = transparentize;
var _color = _interopRequireDefault(require("@kurkle/color"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var chartColors = exports.chartColors = {
  default: {
    primary: '#00D1B2',
    info: '#209CEE',
    danger: '#FF3860'
  }
};
var getRandomColor = exports.getRandomColor = function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
var randomChartData = function randomChartData(n) {
  var data = [];
  for (var i = 0; i < n; i++) {
    data.push(Math.round(Math.random() * 200));
  }
  return data;
};
var datasetObject = function datasetObject(color, points) {
  return {
    fill: false,
    borderColor: chartColors.default[color],
    borderWidth: 2,
    borderDash: [],
    borderDashOffset: 0.0,
    pointBackgroundColor: chartColors.default[color],
    pointBorderColor: 'rgba(255,255,255,0)',
    pointHoverBackgroundColor: chartColors.default[color],
    pointBorderWidth: 20,
    pointHoverRadius: 4,
    pointHoverBorderWidth: 15,
    pointRadius: 4,
    data: randomChartData(points),
    tension: 0.5,
    cubicInterpolationMode: 'default'
  };
};
var sampleChartData = exports.sampleChartData = function sampleChartData() {
  var points = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 9;
  var labels = [];
  for (var i = 1; i <= points; i++) {
    labels.push("0".concat(i));
  }
  return {
    labels: labels,
    datasets: [datasetObject('primary', points), datasetObject('info', points), datasetObject('danger', points)]
  };
};
var colorIndex = 0;
var getSequentialColor = exports.getSequentialColor = function getSequentialColor() {
  var predefinedColors = ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#F0FF33', '#33FFF0', '#8E33FF', '#FF8E33', '#33FF8E', '#8EFF33'];
  var color = predefinedColors[colorIndex];
  colorIndex = (colorIndex + 1) % predefinedColors.length;
  return color;
};
var resetColorIndex = exports.resetColorIndex = function resetColorIndex() {
  colorIndex = 0;
};
var datasetObjectItemPainel = exports.datasetObjectItemPainel = function datasetObjectItemPainel(value, name, colorSelect) {
  var type = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'line';
  var color = getSequentialColor();
  if (colorSelect) color = colorSelect;
  return {
    label: name,
    fill: false,
    borderColor: color,
    borderWidth: 2,
    borderDash: [],
    borderDashOffset: 0.0,
    pointBackgroundColor: color,
    pointBorderColor: 'rgba(255,255,255,0)',
    pointHoverBackgroundColor: color,
    pointBorderWidth: 20,
    pointHoverRadius: 4,
    pointHoverBorderWidth: 15,
    pointRadius: 0,
    data: value,
    tension: 0.5,
    cubicInterpolationMode: 'default',
    type: type
  };
};
var datasetObjectItem = exports.datasetObjectItem = function datasetObjectItem(value, name, colorSelect) {
  var type = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'line';
  var color = getRandomColor();
  if (colorSelect) color = colorSelect;
  return {
    label: name,
    fill: false,
    borderColor: color,
    borderWidth: 2,
    borderDash: [],
    borderDashOffset: 0.0,
    pointBackgroundColor: color,
    pointBorderColor: 'rgba(255,255,255,0)',
    pointHoverBackgroundColor: color,
    pointBorderWidth: 20,
    pointHoverRadius: 4,
    pointHoverBorderWidth: 15,
    pointRadius: 0,
    data: value,
    tension: 0.5,
    cubicInterpolationMode: 'default',
    type: type
  };
};
function transparentize(value, opacity) {
  var alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return (0, _color.default)(value).alpha(alpha).rgbString();
}
var CHART_COLORS = exports.CHART_COLORS = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)'
};