"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$personalData, _vm$personalData2, _vm$personalData3, _vm$personalData4, _vm$personalData5, _vm$personalData6, _vm$personalData7, _vm$personalData8;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("h3", {
    staticClass: "title",
    staticStyle: {
      "margin-top": "-25px"
    }
  }, [_vm._v("\n    Prova de Vida " + _vm._s((_vm$personalData = _vm.personalData) !== null && _vm$personalData !== void 0 && _vm$personalData.name ? " - ".concat((_vm$personalData2 = _vm.personalData) === null || _vm$personalData2 === void 0 ? void 0 : _vm$personalData2.name) : "") + "\n  ")]), _vm._v(" "), _c("el-card", {
    staticClass: "box-card",
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("div", {
    staticClass: "personal-infos"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "header-personal-infos"
  }, [_c("div", [_vm._v("CPF: "), _c("strong", [_vm._v(_vm._s(_vm.cpf))])]), _vm._v(" "), _c("div", [_vm._v("Nome: "), _c("strong", [_vm._v(_vm._s((_vm$personalData3 = _vm.personalData) === null || _vm$personalData3 === void 0 ? void 0 : _vm$personalData3.name))])]), _vm._v(" "), _c("div", [_vm._v("Status: "), _c("strong", [_vm._v(_vm._s((_vm$personalData4 = _vm.personalData) === null || _vm$personalData4 === void 0 ? void 0 : _vm$personalData4.status))])]), _vm._v(" "), ((_vm$personalData5 = _vm.personalData) === null || _vm$personalData5 === void 0 ? void 0 : _vm$personalData5.status) === "downloaded" ? _c("div", [_vm._v("Data do download: "), _c("strong", [_vm._v(_vm._s(_vm._f("date")((_vm$personalData6 = _vm.personalData) === null || _vm$personalData6 === void 0 ? void 0 : _vm$personalData6.dateDownload)))])]) : _vm._e(), _vm._v(" "), ((_vm$personalData7 = _vm.personalData) === null || _vm$personalData7 === void 0 ? void 0 : _vm$personalData7.status) === "completed" || ((_vm$personalData8 = _vm.personalData) === null || _vm$personalData8 === void 0 ? void 0 : _vm$personalData8.status) === "downloaded" ? _c("div", [_c("b-button", {
    attrs: {
      type: "is-info",
      "icon-left": "download"
    },
    on: {
      click: _vm.downloadData
    }
  }, [_vm._v("\n                Baixar dados\n              ")])], 1) : _vm._e()])])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "diamond-container"
  }, [_c("canvas", {
    attrs: {
      id: "diamond-canvas",
      width: "343",
      height: "330"
    }
  })]), _vm._v(" "), _vm.scoreRisco.value ? _c("div", {
    staticClass: "vue-ellipse-risco-container"
  }, [_c("vue-ellipse-progress", {
    attrs: {
      half: "",
      angle: "0",
      size: 230,
      thickness: "5%",
      "line-mode": "in",
      progress: _vm.scoreRisco.valueAsInt,
      color: _vm.scoreRisco.gradientLineColors,
      "legend-formatter": function legendFormatter(_ref) {
        var currentValue = _ref.currentValue;
        return "<div class='ellipse-progress-info-risco'><span>".concat(currentValue, "%</span><span>Score de Valida\xE7\xE3o</span></div>");
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "tag-legenda-risco"
  }, [_c("el-tag", {
    attrs: {
      id: "tag-score-risco",
      color: _vm.scoreRisco.legendTagBgColor,
      effect: "dark"
    }
  }, [_vm._v(_vm._s(_vm.scoreRisco.legendTagText))])], 1)], 1) : _vm._e()]), _vm._v(" "), _c("div", {
    staticStyle: {
      width: "calc(100% - 410px)"
    }
  }, [_c("b-tabs", {
    model: {
      value: _vm.abaAtiva,
      callback: function callback($$v) {
        _vm.abaAtiva = $$v;
      },
      expression: "abaAtiva"
    }
  }, _vm._l(["CPF", "Celular", "Email", "Documentos", "Face"], function (aba) {
    return _c("b-tab-item", {
      key: aba,
      attrs: {
        label: aba
      }
    }, [aba === "Face" ? _c("CardValidaFace", {
      attrs: {
        "item-id": _vm.itemId,
        data: _vm.personalData
      }
    }) : aba === "Documentos" ? _c("CardValidaDocuments", {
      attrs: {
        "item-id": _vm.itemId,
        data: _vm.personalData
      }
    }) : _c("el-table", {
      staticStyle: {
        width: "100%"
      },
      attrs: {
        border: "",
        stripe: "",
        data: _vm.insights[aba.toLowerCase()],
        "default-sort": {
          prop: "relevance"
        }
      }
    }, [_c("el-table-column", {
      attrs: {
        prop: "relevance",
        label: "Relevância",
        width: "122",
        sortable: "",
        filters: [{
          text: "Positivo",
          value: "Positivo"
        }, {
          text: "Neutro",
          value: "Neutro"
        }, {
          text: "Alerta",
          value: "Alerta"
        }],
        "filter-method": _vm.relevanceFilterHandler
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(scope) {
          return [_c("div", {
            staticStyle: {
              "text-align": "center"
            }
          }, [_c("el-tag", {
            attrs: {
              size: "mini",
              type: scope.row.relevance === "Alerta" ? "danger" : scope.row.relevance === "Neutro" ? "info" : ""
            }
          }, [_vm._v(_vm._s(scope.row.relevance))])], 1)];
        }
      }], null, true)
    }), _vm._v(" "), _c("el-table-column", {
      attrs: {
        prop: "code",
        label: "Código",
        width: "91",
        sortable: ""
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(scope) {
          return [_c("div", {
            staticStyle: {
              "text-align": "center",
              "font-family": "monospace, monospace"
            }
          }, [_vm._v("\n                      " + _vm._s(scope.row.code) + "\n                    ")])];
        }
      }], null, true)
    }), _vm._v(" "), _c("el-table-column", {
      attrs: {
        prop: "insights",
        label: "Descrição",
        sortable: ""
      }
    }), _vm._v(" "), _c("el-table-column", {
      attrs: {
        prop: "category",
        label: "Categoria",
        width: "180",
        sortable: ""
      }
    })], 1)], 1);
  }), 1)], 1)])])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;