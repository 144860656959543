"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'TablePainel',
  props: {
    title: {
      type: String,
      default: 'Tabela'
    },
    rows: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    total: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 5
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    sortIcon: {
      type: String,
      default: 'arrow-up'
    },
    sortIconSize: {
      type: String,
      default: 'small'
    }
  },
  methods: {
    onPageChange: function onPageChange(newPage) {
      this.$emit('page-change', newPage);
    }
  }
};