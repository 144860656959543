"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "modal-card",
    class: {
      loaded: _vm.isLoaded
    }
  }, [_c("section", {
    staticClass: "modal-card-body"
  }, [_c("h1", [_vm._v("Valorizamos sua privacidade")]), _vm._v(" "), _c("p", [_vm._v('Utilizamos cookies para aprimorar sua experiência de navegação. Ao clicar em "Aceitar", você concorda com nosso uso de cookies.')]), _vm._v(" "), _c("div", {
    staticClass: "button-container"
  }, [_c("b-button", {
    attrs: {
      slot: "footer",
      type: "is-info",
      outlined: ""
    },
    on: {
      click: _vm.declineCookies
    },
    slot: "footer"
  }, [_vm._v("\n        Rejeitar\n      ")]), _vm._v(" "), _c("b-button", {
    attrs: {
      slot: "footer",
      type: "is-info"
    },
    on: {
      click: _vm.acceptCookies
    },
    slot: "footer"
  }, [_vm._v("\n        Aceitar\n      ")])], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;