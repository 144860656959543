"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TableDetailCard = _interopRequireDefault(require("../../Dashboard/components/TableDetailCard.vue"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = exports.default = {
  name: 'DetailsDasboardError',
  components: {
    TableDetailCard: _TableDetailCard.default
  },
  props: {
    createHiveError: {
      type: Object,
      required: true
    },
    hiveProviderError: {
      type: Object,
      required: true
    },
    providerHiveError: {
      type: Object,
      required: true
    },
    hiveClientError: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      detailsProcessing: {
        name: 'inProcessing',
        type: 'processing',
        typeLoad: 'is-info',
        showHttp: true
      },
      itemsDetailsError: {
        name: 'withError',
        type: 'error',
        typeLoad: 'is-danger',
        showHttp: true
      },
      drawerCardDetails: {
        isLoading: true,
        withError: {
          show: false,
          title: '',
          activeTab: 0
        }
      }
    };
  }
};