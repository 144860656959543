"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("@/components");
var _default = exports.default = {
  name: 'ProductsServices',
  bodyClass: 'products-services-page',
  components: {
    CardMonitor: _components.CardMonitor
  },
  data: function data() {
    return {
      activeTab: 0,
      filter: '',
      list: [{
        name: 'Páginas e Menu Sidebar',
        path: '/access-control/pages'
      }, {
        name: 'Grupos de Acesso',
        path: '/access-control/access-group'
      }]
    };
  },
  methods: {
    openCard: function openCard(item) {
      console.log(item);
      this.$router.push({
        path: item.path
      });
    }
  }
};