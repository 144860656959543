"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")])]), _vm._v(" "), _c("div", {
    staticClass: "columns cols-form"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("card", {
    staticClass: "max-width offset p-4"
  }, [_c("h1", {
    staticClass: "subtitle"
  }, [_vm._v(_vm._s(_vm.title))]), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "Nome",
      placeholder: "Informe o nome do grupo de acesso",
      error: _vm.$v.form.name.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.name, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.name.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.name, "$model", $$v);
      },
      expression: "$v.form.name.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "Código Grupo de Acesso",
      placeholder: "Informe o código do grupo de acesso",
      error: _vm.$v.form.codGroup.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.codGroup, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.codGroup.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.codGroup, "$model", $$v);
      },
      expression: "$v.form.codGroup.$model"
    }
  }), _vm._v(" "), _c("b-checkbox", {
    staticClass: "mt-4 mb-4",
    model: {
      value: _vm.form.isAdmin,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isAdmin", $$v);
      },
      expression: "form.isAdmin"
    }
  }, [_vm._v("\n            Administrador\n          ")]), _vm._v(" "), _c("b-checkbox", {
    staticClass: "mt-4 mb-4",
    model: {
      value: _vm.form.active,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "active", $$v);
      },
      expression: "form.active"
    }
  }, [_vm._v("\n            Ativo\n          ")]), _vm._v(" "), _c("div", {
    staticClass: "list-support mt-6"
  }, [_c("div", {
    staticClass: "contatiner-link-page mb-5"
  }, [_c("h1", {
    staticClass: "subtitle"
  }, [_vm._v("Páginas Vínculadas")]), _vm._v(" "), _c("b-button", {
    staticClass: "button is-info",
    on: {
      click: _vm.openLinkPageToGroup
    }
  }, [_vm._v("Vincular Página")])], 1), _vm._v(" "), _c("b-table", {
    staticClass: "table-list-modern",
    attrs: {
      data: !_vm.listLinksPagesToGroup.length ? [] : _vm.listLinksPagesToGroup,
      narrowed: true,
      hoverable: true,
      focusable: true,
      "mobile-cards": true
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function fn() {
        return [_c("div", {
          staticClass: "has-text-centered"
        }, [_vm._v("Não há registros")])];
      },
      proxy: true
    }])
  }, [_c("b-table-column", {
    attrs: {
      field: "Name",
      label: "Nome"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("span", {
          staticClass: "click",
          on: {
            click: function click($event) {
              return _vm.editBusinessUnits(props.row);
            }
          }
        }, [_vm._v(_vm._s(props.row.name))])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "Path",
      label: "Path"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("span", {
          staticClass: "click",
          on: {
            click: function click($event) {
              return _vm.editBusinessUnits(props.row);
            }
          }
        }, [_vm._v("\n                  " + _vm._s(props.row.path) + "\n                ")])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "CodPage",
      label: "Código Da página"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("span", {
          staticClass: "click",
          on: {
            click: function click($event) {
              return _vm.editBusinessUnits(props.row);
            }
          }
        }, [_vm._v("\n                  " + _vm._s(props.row.codPage) + "\n                ")])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "Active",
      label: "Ativo",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("span", {
          staticClass: "click",
          on: {
            click: function click($event) {
              return _vm.editBusinessUnits(props.row);
            }
          }
        }, [_vm._v("\n                  " + _vm._s(props.row.active ? "Ativo" : "Inativo") + "\n                ")])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Remover",
      centered: "",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("b-button", {
          staticClass: "btn-delete",
          attrs: {
            "icon-right": "delete"
          },
          on: {
            click: function click($event) {
              return _vm.confirmDelete(props.row);
            }
          }
        })];
      }
    }])
  })], 1)], 1)], 1)], 1)]), _vm._v(" "), _c("div", {
    staticClass: "cols-btns-list"
  }, [_c("b-button", {
    staticClass: "button is-info",
    on: {
      click: _vm.saveData
    }
  }, [_vm._v("Salvar")])], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;